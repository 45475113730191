import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'
import { GRADE } from '@/domain/boulder/grades'
import { HOLD } from '@/domain/boulder/hold'

export const options = {
    theme: {
        options: {
            variations: false // do not generate variations of theme colors
        },
        themes: {
            light: {
                textAccent: colors.indigo.accent2,
                primary: colors.indigo.base, // #E53935
                secondary: colors.orange.base, // #FFCDD2
                accent: colors.indigo.base, // #3F51B5
                alert: colors.red.base,
                [GRADE.EASY]: colors.grey.lighten3,
                [GRADE.MODERATE]: colors.yellow.accent2,
                [GRADE.DIFFICULT]: colors.green.base,
                [GRADE.SEVERE]: colors.blue.base,
                [GRADE.EXTREME]: colors.red.darken1,
                [HOLD.START]: '#0527f8',
                [HOLD.BETWEEN]: colors.yellow.accent2,
                [HOLD.TOP]: '#28fa01'
            }
        }
    }
}
Vue.use(Vuetify)

export default new Vuetify(options)
