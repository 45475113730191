import { UserService } from '@/domain/User/userService'
import { AxiosInstance } from 'axios'
import { Mapper } from '@/application/Mapper'
import { showSnackbar, SNACKBAR } from '@/infrastructure/EventBus/global-actions/showSnackbar'
import { User } from '@/domain/model/User'
import { instance } from '@/infrastructure/servicesUsingAxios/axiosSetup'
import { ERROR_CODE } from '@/application/Errors'

const userServiceUsingAxios = (axios: AxiosInstance): UserService => ({
    register: async (form) => {
        try {
            await axios.post('/user', form)
        } catch (e) {
            const errorCode = e.response?.data?.message
            if ([ERROR_CODE.NAME_ALREADY_USED, ERROR_CODE.EMAIL_ALREADY_USED].includes(errorCode)) {
                throw new Error(errorCode)
            }
            console.log(e)
        }
    },
    logIn: async (loginData) => {
        try {
            const result = await axios.post('/session', loginData)

            if (!result) {
                return null
            }

            return Mapper.dataToUser(result.data)
        } catch (e) {
            const statusCode = e.response?.status
            if (statusCode === 401) {
                throw new Error(ERROR_CODE.CREDENTIALS)
            } else {
                throw e
            }
        }
    },
    logOut: async (): Promise<void> => {
        try {
            await axios.delete('/session')
        } catch (e) {
            console.log(e)
        }
    },
    update: async (userInput: User): Promise<User | null> => {
        try {
            const result = await axios.put('/user', userInput)

            if (!result) {
                return null
            }

            return Mapper.dataToUser(result.data)
        } catch (e) {
            console.log(e)

            showSnackbar({ type: SNACKBAR.ERROR, message: 'Update verkackt' })
        }

        return null
    }
})

export default userServiceUsingAxios(instance)
