import { Boulder } from '@/domain/boulder'
import { SectorId } from '@/domain/sector/sector-id'
import { BoulderId } from '@/domain/boulder/Id'
import {
    validatedArray,
    validatedCategory,
    validatedGrade,
    validatedHolds,
    validatedObject,
    validatedString,
    validatedStringArray
} from '@/application/validators'
import { User } from '@/domain/model/User'

export class Mapper {
    public static dataToBoulder(data: { [key: string]: unknown }): Boulder {
        return {
            name: validatedString(data.name),
            grade: validatedGrade(data.grade),
            sector: SectorId.fromString(validatedString(data.sector)),
            description: validatedString(data.description),
            category: validatedCategory(data.category),
            holds: validatedHolds(data.holds),
            id: BoulderId.fromString(validatedString(data.id)),
            createdBy: validatedString(data.createdBy),
            createdAt: new Date(validatedString(data.createdAt)),
            updatedAt: new Date(validatedString(data.updatedAt))
        }
    }

    public static dataToUser(data: { [key: string]: unknown }): User {
        return {
            email: validatedString(data.email),
            id: validatedString(data.id),
            name: validatedString(data.name),
            topped: validatedStringArray(data.topped),
            bookmarked: validatedStringArray(data.bookmarked)
        }
    }

    static dataToBoulders(data: { [key: string]: unknown }[]): Boulder[] {
        return validatedArray(data).map((value: unknown) => {
            const boulder = validatedObject(value)
            const boulderId = validatedString(boulder.id)

            return {
                id: BoulderId.fromString(boulderId),
                name: validatedString(boulder.name),
                category: validatedCategory(boulder.category),
                description: boulder.description ? validatedString(boulder.description) : '',
                grade: validatedGrade(boulder.grade),
                createdAt: new Date(validatedString(boulder.createdAt)),
                updatedAt: new Date(validatedString(boulder.updatedAt)),
                sector: SectorId.fromString(validatedString(boulder.sector)),
                holds: validatedHolds(boulder.holds),
                createdBy: validatedString(boulder.createdBy)
            }
        })
    }
}
