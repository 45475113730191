import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { checkAuthentication } from '@/infrastructure/router/hooks'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        redirect: '/boulder/list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../../views/Login.vue')
    },
    {
        path: '/boulder/create',
        name: 'boulder-create',
        component: () => import('../../views/boulder/create/create.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/boulder/list',
        name: 'boulder-list',
        component: () => import('../../views/boulder/list/list.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/boulder/detail/:id',
        name: 'boulder-detail',
        component: () => import('../../views/boulder/detail/detail.vue'),
        props: (route) => ({
            id: route.params.id
        }),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('../../views/SignUp.vue')
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach(checkAuthentication)

export default router
