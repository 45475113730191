import EventBus, { ACTIONS } from '@/infrastructure/EventBus'

export const SNACKBAR = {
    ERROR: 'error',
    SUCCESS: 'success'
}
export type SnackbarOptions = {
    message: string
    type: typeof SNACKBAR[keyof typeof SNACKBAR]
}
export const showSnackbar = (options: SnackbarOptions): void => {
    EventBus.$emit(ACTIONS.SNACKBAR, options)
}
