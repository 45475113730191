import { ValidationError } from '@/application/Errors/ValidationError'
import { grades, GradeType } from '@/domain/boulder/grades'
import { category, CategoryType } from '@/domain/boulder/categories'
import { Hold, hold as holds } from '@/domain/boulder/hold'

export const validatedString = (value: unknown): string => {
    const validatedString = value
    if (typeof validatedString !== 'string') {
        throw new ValidationError('string', typeof validatedString)
    }

    return validatedString
}

export const validatedArray = (value: unknown): unknown[] => {
    const result = value
    if (!result || !Array.isArray(result)) {
        throw new ValidationError('array', typeof result)
    }

    return result
}

export const validatedObject = (value: unknown): { [key: string]: unknown } => {
    if (typeof value !== 'object' || Array.isArray(value) || value === null) {
        throw new ValidationError('object', typeof value)
    }

    return value as { [key: string]: unknown }
}

export const validatedStringArray = (value: unknown): string[] => {
    return validatedArray(value).map((item) => validatedString(item))
}

export function isGrade(value: unknown): value is GradeType {
    return !!value && typeof value === 'string' && grades.includes(value)
}

export const validatedGrade = (value: unknown): GradeType => {
    const grade = value

    if (!isGrade(grade)) {
        const actual = typeof grade !== 'string' ? typeof grade : grade

        throw new ValidationError('GradeType', actual)
    }

    return grade
}

export function isCategory(value: unknown): value is CategoryType {
    return !!value && typeof value === 'string' && category.includes(value)
}

export const validatedCategory = (value: unknown): CategoryType => {
    const category = value
    if (!isCategory(category)) {
        const actual = typeof category !== 'string' ? typeof category : category

        throw new ValidationError('CategoryType', actual)
    }

    return category
}

export function isHold(value: unknown): value is Hold {
    if (typeof value !== 'object' || Array.isArray(value) || value === null) {
        return false
    }

    const hold = value as { type: unknown; x: unknown; y: unknown }

    if (!Number.isInteger(hold.x)) {
        return false
    }

    if (!Number.isInteger(hold.y)) {
        return false
    }

    const { type } = hold

    return !(!type || typeof type !== 'string' || !holds.includes(type))
}

export function isHolds(value: unknown): value is Hold[] {
    return Array.isArray(value) && value.every((item) => isHold(item))
}

export const validatedHolds = (holds: unknown): Hold[] => {
    if (!isHolds(holds)) {
        throw new ValidationError('Hold[]', `something else of type ${typeof holds}`)
    }

    return holds
}
