// Taken from https://github.com/nesterow/provide-consume-decorator

export const __provide__ = '$$provide$$' // container key, doesn't work with Symbol

export const PROVIDER = {
    BOULDER: '$$boulder$$',
    USER: '$$user$$',
    SECTOR: '$$sector$$'
} as const
type ProviderType = typeof PROVIDER[keyof typeof PROVIDER]

export const PATH = {
    BOULDER_API: 'boulder',
    USER_API: 'user',
    FILTER_API: 'filter',
    SECTOR_API: 'sector',
    CONTROL_API: 'contorl'
} as const
type PathType = typeof PATH[keyof typeof PATH]

/**
 * Consume decorator
 * Transforms a class member into getter that resolves an instance from provider container
 * @prop path: string - name of the registered entitys
 */
export function consume(path: PathType, provider?: ProviderType): PropertyDecorator {
    // tslint:disable-next-line: only-arrow-functions
    // eslint-disable-next-line @typescript-eslint/ban-types
    return function (target: object, propertyKey: string | symbol) {
        Object.defineProperty(target, propertyKey, {
            get() {
                const service: any = this[provider || __provide__][path]
                return typeof service === 'function' ? service.bind(this)() : service
            }
        })
    }
}

/**
 * Provide decorator for vuex
 * Adds a dependecy contatiner to the class instance, can work whether with VuexStore object or a javascript class
 * @prop data: Object<{[string]: any}> - a javascript object that provides a dependency container
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function provideVuex(data: any, provider: ProviderType): ClassDecorator {
    // tslint:disable-next-line: only-arrow-functions
    return function (constructor: any) {
        if (constructor.getters) {
            constructor.getters[provider] = () => data
        } else {
            Object.defineProperty(constructor.prototype, provider, {
                get() {
                    return data
                }
            })
        }
    }
}

/**
 * Provide decorator for a javascript class
 * Adds a dependecy contatiner to the class instance
 * @prop data: Object<{[string]: any}> - a javascript object that provides a dependency container
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function provide(data: any): ClassDecorator {
    // tslint:disable-next-line: only-arrow-functions
    return function (constructor: any) {
        Object.defineProperty(constructor.prototype, __provide__, {
            get() {
                return data
            }
        })
    }
}
