export interface Hold {
    x: number
    y: number
    type: HoldType
}

export const HOLD = {
    START: 'start',
    BETWEEN: 'between',
    TOP: 'top'
} as const

export const holdText = {
    [HOLD.START]: 'start',
    [HOLD.BETWEEN]: 'regular',
    [HOLD.TOP]: 'top'
}

export type HoldType = typeof HOLD[keyof typeof HOLD]

export const hold = {
    types: Object.values(HOLD) as HoldType[],
    toText(type: HoldType): string {
        return holdText[type]
    },
    includes(type: string): boolean {
        return this.types.includes(type as HoldType)
    }
}
