import Vue from 'vue'
import App from './App.vue'
import router from './infrastructure/router'
import store from './infrastructure/store'
import vuetify from './infrastructure/plugins/vuetify'
import Vuelidate from 'vuelidate'
import './styles.sass'

Vue.config.productionTip = false
Vue.use(Vuelidate)
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount('#app')
