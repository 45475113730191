export const CATEGORY = {
    TECHNICAL: 'technical',
    SLABBY: 'slabby',
    CRIMPY: 'crimpy',
    SLOPPERY: 'sloppery',
    ATHLETIC: 'athletic',
    DYNO: 'dyno',
    WARMUP: 'warmup'
} as const

export const categoryText = {
    [CATEGORY.TECHNICAL]: 'technisch',
    [CATEGORY.SLABBY]: 'pladdich',
    [CATEGORY.CRIMPY]: 'crimpy',
    [CATEGORY.SLOPPERY]: 'sloperig',
    [CATEGORY.ATHLETIC]: 'athletisch',
    [CATEGORY.DYNO]: 'dyno',
    [CATEGORY.WARMUP]: 'Wundertüte'
}

export type CategoryType = typeof CATEGORY[keyof typeof CATEGORY]
export type CategoryItem = { text: string; value: CategoryType }

interface Category {
    types: CategoryType[]
    asItems(): CategoryItem[]
    toText(type: CategoryType): string
    includes(category: string): boolean
}

export const category: Category = {
    types: Object.values(CATEGORY) as CategoryType[],
    includes(category: string): boolean {
        return this.types.includes(category as CategoryType)
    },
    toText: (type: CategoryType): string => {
        return categoryText[type]
    },
    asItems() {
        return this.types.map((type) => ({
            text: this.toText(type),
            value: type
        }))
    }
}
