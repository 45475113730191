export const GRADE = {
    EASY: 'easy',
    MODERATE: 'moderate',
    DIFFICULT: 'difficult',
    SEVERE: 'severe',
    EXTREME: 'extreme'
} as const

export const gradeText = {
    [GRADE.EASY]: 'Henkelig (3a - 4a)',
    [GRADE.MODERATE]: 'Plaisir (4a - 5a)',
    [GRADE.DIFFICULT]: 'Sportlich (5a - 6a)',
    [GRADE.SEVERE]: 'Knackig (6a - 7a)',
    [GRADE.EXTREME]: 'Allez! (7a - 8a)'
}

export const gradeShort = {
    [GRADE.EASY]: 'HE',
    [GRADE.MODERATE]: 'PL',
    [GRADE.DIFFICULT]: 'SP',
    [GRADE.SEVERE]: 'KN',
    [GRADE.EXTREME]: 'AL'
}

export type GradeType = typeof GRADE[keyof typeof GRADE]
export type GradeItem = { text: string; value: GradeType }

interface Grade {
    types: GradeType[]
    asItems(): GradeItem[]
    toText(type: GradeType): string
    includes(grade: string): boolean
}

export const grades: Grade = {
    types: Object.values(GRADE) as GradeType[],
    includes(grade: string): boolean {
        return this.types.includes(grade as GradeType)
    },
    asItems() {
        return this.types.map((type: GradeType) => ({
            text: gradeText[type],
            value: type
        }))
    },
    toText(type) {
        return gradeText[type]
    }
}
