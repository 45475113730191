import store from '../index'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { User } from '@/domain/model/User'
import { LoginData, RegistrationData, UserService } from '@/domain/User/userService'
import userServiceUsingAxios from '@/infrastructure/servicesUsingAxios/userServiceUsingAxios'
import { UserSpecificBoulderData } from '@/domain/User/UserSpecificBoulderData'
import { IUserStore } from '@/infrastructure/store/user/types'
import { consume, PATH, PROVIDER, provideVuex } from '@/utils/provide-consum-decorator'

@Module({
    dynamic: true,
    store,
    name: 'user',
    namespaced: true,
    preserveState: sessionStorage.getItem('vuex') !== null
})
@provideVuex({ [PATH.USER_API]: () => userServiceUsingAxios }, PROVIDER.USER)
export class UserStore extends VuexModule implements IUserStore {
    @consume(PATH.USER_API, PROVIDER.USER) userService!: UserService

    authenticatedUser: User | null = null

    get user(): User | null {
        return this.authenticatedUser
    }

    get authenticated(): boolean {
        return this.authenticatedUser !== null
    }

    get userSpecificBoulderData(): UserSpecificBoulderData {
        return {
            topped: this.authenticatedUser?.topped || [],
            bookmarks: this.authenticatedUser?.bookmarked || []
        }
    }

    @Mutation
    setUser(user: User | null): void {
        this.authenticatedUser = user
    }

    @Action
    async logout(): Promise<void> {
        try {
            await this.userService.logOut()
        } catch (e) {
            console.log(e)
        } finally {
            this.context.commit('setUser', null)
        }
    }

    @Action({ rawError: true })
    async registerUser(registrationForm: RegistrationData): Promise<void> {
        await this.userService.register(registrationForm)
        await this.context.dispatch('loginUser', registrationForm)
    }

    @Action({ rawError: true })
    async loginUser(loginData: LoginData): Promise<void> {
        const user = await this.userService.logIn({
            email: loginData.email,
            password: loginData.password
        })
        if (user !== null) {
            this.context.commit('setUser', {
                id: user.id,
                name: user.name,
                email: user.email,
                bookmarked: user.bookmarked,
                topped: user.topped
            })
        }
    }

    @Action
    async updateUser(userInput: User): Promise<void> {
        let user: User | null = null

        try {
            user = await this.userService.update(userInput)
        } catch (e) {
            console.error(e)
        }
        if (user !== null) {
            this.context.commit('setUser', {
                id: user.id,
                name: user.name,
                email: user.email,
                bookmarked: user.bookmarked,
                topped: user.topped
            })
        }
    }

    @Action
    async removeBookmark(id: string): Promise<void> {
        if (this.user) {
            await this.context.dispatch('updateUser', {
                ...this.user,
                bookmarked: this.user.bookmarked.filter((bookmark) => bookmark !== id)
            })
        }
    }

    @Action
    async addBookmark(id: string): Promise<void> {
        if (this.user) {
            await this.context.dispatch('updateUser', {
                ...this.user,
                bookmarked: [...this.user.bookmarked, id]
            })
        }
    }

    @Action
    async removeTopped(id: string): Promise<void> {
        if (this.user) {
            await this.context.dispatch('updateUser', {
                ...this.user,
                topped: this.user.topped.filter((top) => top !== id)
            })
        }
    }

    @Action
    async addTopped(id: string): Promise<void> {
        if (this.user) {
            await this.context.dispatch('updateUser', {
                ...this.user,
                topped: [...this.user.topped, id]
            })
        }
    }
}
