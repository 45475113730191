import { Route } from 'vue-router'
import { NavigationGuardNext } from 'vue-router/types/router'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/infrastructure/store/user'

const authModule = getModule(UserStore)

export const checkAuthentication = (to: Route, from: Route, next: NavigationGuardNext): void => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (authModule.authenticated) {
            next()

            return
        }

        next({ name: 'login' })
    } else {
        next()
    }
}
