









import Vue from 'vue'
import EventBus, { ACTIONS } from '@/infrastructure/EventBus'
import { SNACKBAR, SnackbarOptions } from '@/infrastructure/EventBus/global-actions/showSnackbar'
import SnackBar from '@/components/SnackBar.vue'

type AppData = { snackbar: boolean; options: SnackbarOptions }

export default Vue.extend({
    name: 'App',
    components: { SnackBar },
    data: (): AppData => ({
        snackbar: false,
        options: { message: '', type: SNACKBAR.SUCCESS }
    }),

    mounted() {
        EventBus.$on(ACTIONS.SNACKBAR, (options: SnackbarOptions) => {
            this.options = options
            this.snackbar = true
        })
    }
})
