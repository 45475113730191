export class Id {
    private readonly id: string

    constructor(id: string) {
        this.id = id
    }

    static fromString(id: string): Id {
        return new Id(id)
    }

    public asString(): string {
        return this.id
    }
}
