






import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import { SNACKBAR, SnackbarOptions } from '@/infrastructure/EventBus/global-actions/showSnackbar'

@Component({})
export default class SnackBar extends Vue {
    @Prop({ type: Object, required: true }) options!: SnackbarOptions
    @VModel({ type: Boolean, required: true, default: false })
    snackbar!: boolean

    get color(): string {
        if (this.options.type === SNACKBAR.ERROR) {
            return 'error'
        }

        return 'success'
    }
}
